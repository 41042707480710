var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BE_SERVICE_API, NATIVE_EVENTS } from '@constants/constants';
let appsAttribute;
export const initializeAppsAttribute = () => __awaiter(void 0, void 0, void 0, function* () {
    const jsonAppsAttribute = yield window.JSBridge.send(NATIVE_EVENTS.token);
    if (jsonAppsAttribute) {
        appsAttribute = JSON.parse(jsonAppsAttribute);
        return;
    }
    appsAttribute = {
        token: '',
        countryCode: '',
        publicId: '',
        currentLocation: '',
    };
    return;
});
export const getCountryCode = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!appsAttribute) {
        yield initializeAppsAttribute();
    }
    return appsAttribute.countryCode ? appsAttribute.countryCode : 'sg';
});
export const getPublicId = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!appsAttribute) {
        yield initializeAppsAttribute();
    }
    return appsAttribute.publicId ? appsAttribute.publicId : '';
});
export const getCurrentLocation = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!appsAttribute) {
        yield initializeAppsAttribute();
    }
    return appsAttribute.currentLocation ? appsAttribute.currentLocation : '';
});
export const configWithAuthorization = (beService = BE_SERVICE_API.jaya, config = {}) => {
    switch (beService) {
        case BE_SERVICE_API.jaya:
            config.headers = Object.assign(Object.assign({}, config.headers), { Authorization: appsAttribute.token });
            break;
        case BE_SERVICE_API.geoPoi:
            config.headers = Object.assign(Object.assign({}, config.headers), { 'X-MTS-SSID': appsAttribute.token });
            break;
    }
    return config;
};
