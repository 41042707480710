import { hot } from 'react-hot-loader/root';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { ScribeProvider } from '@hooks/useScribe';
import { Spinner } from '@components';
import styles from './App.less';
import { VConsoleProvider } from '@hooks/useVConsole';
import Toasty from '@components/toasty';
const renderRoutes = (routes) => routes.map(route => {
    return (React.createElement(Route, { key: route.pathname, path: route.pathname, element: route.element }));
});
const App = () => (React.createElement("div", { className: styles.layout },
    React.createElement(Toasty, null),
    React.createElement(React.Suspense, { fallback: React.createElement(Spinner, null) },
        React.createElement(ScribeProvider, null,
            React.createElement(VConsoleProvider, null,
                React.createElement(Routes, null, renderRoutes(ROUTES)))))));
App.displayName = 'App';
export default hot(App);
