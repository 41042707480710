/* eslint-disable react/display-name */

import React from 'react';
import { Routes } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { Spinner, ProtectedRoute } from '@components';

const renderRoutes = routes =>
  routes.map(route => {
    if (route.children) {
      return renderRoutes(route.children);
    }
    return (
      <ProtectedRoute
        key={route.pathname}
        path={route.pathname}
        element={route.element}
      />
    );
  });

const AppRoutes = () => (
  <React.Suspense fallback={<Spinner />}>
    <Routes>
      {renderRoutes(ROUTES)}
      {/* <React.Fragment>
        <ProtectedRoute
          path="*"
          element={<NotFoundPage />}
        />
      </React.Fragment> */}
    </Routes>
  </React.Suspense>
);

AppRoutes.displayName = 'AppRoutes';

export default AppRoutes;
